/* global translations */

/** @typedef {import("../custom-elements/vi-wishlist").default} ViWishlist */

class MPconConfigurator {
	buttonToggleFullscreenDefaultText;

	/**
	 * @param {HTMLElement} element
	 */
	constructor(element) {
		this.element = element;

		/** @type {HTMLIFrameElement} iframeElement */
		const iframeElement = element.querySelector('iframe');
		this.buttonToggleFullscreenElement = element.querySelector('button[data-action="toggle-fullscreen"]');
		const { origin } = iframeElement.contentWindow;

		window.addEventListener('message', (event) => {
			/** @var {MessageEvent} event */
			if (event.origin !== origin) return;
			if (event.data.status === 'success') {
				(async () => {
					/** @type {ViWishlist} */
					const wishlist = document.querySelector('vi-wishlist[variant="active"]');
					await wishlist.loadData();
					wishlist.open();
				})();
			}
		});

		this.buttonToggleFullscreenElement?.addEventListener('click', this.toggleFullscreen.bind(this));

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.toggleFullscreen(true);
			}
		});

		this.buttonToggleFullscreenDefaultText = this.buttonToggleFullscreenElement?.querySelector('.a-button__text')?.textContent;
	}

	toggleFullscreen(forceExit) {
		const {
			element,
			buttonToggleFullscreenElement,
			buttonToggleFullscreenDefaultText,
		} = this;
		const exitFullscreen = forceExit === true || element.getAttribute('data-state') === 'fullscreen';

		if (buttonToggleFullscreenElement) {
			buttonToggleFullscreenElement.querySelector(':scope > .a-button__icon:nth-of-type(1)').toggleAttribute('hidden', !exitFullscreen);
			buttonToggleFullscreenElement.querySelector(':scope > .a-button__icon:nth-of-type(2)').toggleAttribute('hidden', exitFullscreen);
		}

		if (exitFullscreen) {
			buttonToggleFullscreenElement.querySelector('.a-button__text').textContent = buttonToggleFullscreenDefaultText;
			buttonToggleFullscreenElement.removeAttribute('data-size');
			element.removeAttribute('data-state');
		} else {
			buttonToggleFullscreenElement.querySelector('.a-button__text').textContent = translations['fullscreen.exit'];
			buttonToggleFullscreenElement.setAttribute('data-size', 'small');
			element.setAttribute('data-state', 'fullscreen');
		}
	}
}

[...document.querySelectorAll('.m-pcon-configurator')].map((element) => new MPconConfigurator(element));
